import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      components: {
        structureBuilder: {
          roomNameTitle: "Room details for: {{roomName}}",
          roomNameDescription:
            "Provide a distinctive name for each room type available at your accommodation. The room name should capture the essence and style of the space, such as 'Ocean View Suite' or 'Tranquil Garden Room'. A well-chosen name helps guests visualize their stay and select the room that best fits their preferences.",
          bedTypeSelect: "Select bed type(s)",
          sleepingArrangementsSelect:
            "Select all bed types available for this room",
          photosUpload: "Upload {{roomName}} photos",
          addNewRoom: "Add {{pluralText}} new room",
          roomDescription:
            "Enter a detailed description for the {{roomName}} here. Highlight its cosy ambience, room dimensions, bed size, available amenities, and any unique features that make it an ideal choice for travellers seeking comfort and convenience.",
          roomBasicsTitle: "Basics",
          roomBasicsDescription:
            "Provide a comprehensive overview of the basic amenities available in this room. This information helps guests understand the fundamental comforts and conveniences your accommodation offers.",
          roomBathroomTitle: "Bathroom",
          roomBathroomDescription:
            "Describe the style and amenities of the bathroom, emphasizing comfort and luxury.",
          roomExtrasTitle: "Extras",
          roomExtrasDescription:
            "Detail the additional luxurious amenities that set this room apart.",
        },
      },
      home: {
        intro: "Retreats Booking Platform",
      },
      login: {
        title: "Dashboard Login",
        intro: "Please select an option below to continue",
      },
      onboarding: {},
      dashboard: {
        home: {
          notifications: {
            title: "Notifications",
            empty:
              "You currently have no alerts. This section will notify you of any important updates, reminders, or actions needed for your account and listings.",
          },
        },
        listings: {
          upsert: {
            intro: {
              title: "Creating a Listing",
              description:
                "In this stage, you are crafting your retreat listing. Add the retreat dates and provide additional details to combine your accommodation options with the retreat program. This process helps you create a comprehensive and final listing showcasing potential guests' complete retreat experience.",
            },
            name: {
              title: "Listing Name",
              description:
                "Choose a captivating and marketable retreat name that reflects its essence and appeal. This name should inspire potential guests and give them a glimpse of the unique experience awaiting them. Think of a title that stands out and resonates with the theme and atmosphere of your retreat.",
            },
            tagline: {
              title: "Tagline",
              description:
                "Add a short, attractive one-line description that summarises your listing",
            },
            listingIntro: {
              title: "Introduction",
              description:
                "Provide an engaging introduction for your listing. This should be brief yet captivating 1-2 paragraphs that introduces potential guests to your retreat, highlighting its unique ambiance, primary focus, and what sets it apart from others. Use this opportunity to create a strong first impression that piques interest and draws guests into wanting to learn more about what your retreat has to offer.",
            },
            highlights: {
              title: "Highlights & Benefits",
              description:
                "Outline the key features and standout aspects of your retreat that you want to emphasise. This is your chance to showcase what makes your retreat extraordinary and appealing to potential guests.",
            },
            photos: {
              title: "Photos",
              description:
                "Add any photos that are specific to your listing below.",
            },
            requestToBookMode: {
              title: "Instant Booking / Request to Book",
              description:
                "Choose the option that best suits how you want to manage bookings for your retreat:",
              instantTitle: "Instant Booking",
              instantDescription:
                "Bookings are confirmed automatically without needing your approval. Guests can book instantly, providing convenience and speed. By selecting this option, you agree that all bookings are confirmed as soon as they are made.",
              requestTitle: "Request to Book",
              requestDescription:
                "Guests must submit a booking request, and you will decide whether to approve or decline it. The reservation is only confirmed after your approval.",
            },
            accommodation: {
              title: "Accommodation",
              description:
                "Select from the list of available accommodations for your retreat.",
            },
            event: {
              title: "Retreat Program",
              description:
                "Choose the specific retreat program you're offering for this retreat.",
            },
            availability: {
              title: "Availability",
              description:
                "Choose the dates when your retreat listing will be available for booking.",
            },
            paymentsAndRefundsPolicy: {
              title: "Payments & Refund Policy",
              description: "Please select a policy.",
            },
            deposit: {
              title: "Deposit",
              description:
                "Please choose how much deposit you would like to charge your customers based on their booking cost",
            },
            possibleEarnings: {
              title: "Possible Earnings",
              description:
                "We've created approximate estimated earnings you could earn from this listing below.",
            },
            paymentSettings: {
              title: "Payment settings",
              description: "Choose how you'd like to handle payments:",
            },
          },
        },
        accommodation: {
          upsert: {
            intro: {
              title: "Adding an Accommodation",
              description:
                "Detail the lodging arrangements. Include information about room types, amenities, and unique features.",
            },
            name: {
              title: "Accommodation Name",
              description:
                "Enter the name of your accommodation. This should reflect the character and style of the lodging, e.g. Nestled Sanctuary in a Serene Paradise. For internal and post-booking use.",
            },
            description: {
              title: "Description",
              description:
                "Provide an inspiring and detailed description of the accommodation. Highlight its unique charm, comfort, and any distinctive features that enhance the guest experience.",
            },
            category: {
              title: "Accommodation Category",
              description:
                "Select the category that best captures the essence and style of your accommodation to best describe the character and appeal of your lodging.",
            },
            photos: {
              title: "Photo Upload",
              description:
                "Please upload high-quality photos that best represent your retreat and accommodation. Include images that showcase the location's beauty, facilities, amenities, and any unique features. Make sure your photos are well-lit, clear, and inviting, as they greatly influence guest attraction. Additionally, we encourage a diverse gallery to fully illustrate your offering.",
              dragDropDescription:
                "Drag and drop files here, or tap to select images.",
            },
            facilitiesAmenities: {
              title: "Overall Facilities & Amenities",
              description:
                "List the facilities and amenities available at your retreat. This section should include details like Wi-Fi availability, parking, swimming pools, spa services, dining options, and other unique features. Providing a comprehensive overview of what guests can expect in terms of comfort and convenience will help them make informed decisions about their stay.",
            },
            basics: {
              title: "Basics",
              description:
                "Select the essential amenities available at this accommodation. Optional field.",
            },
            services: {
              title: "Services",
              description:
                "Outline the additional services provided at this accommodation. Optional field.",
            },
            indoorSpaces: {
              title: "Indoor Spaces",
              description:
                "Describe your accommodation's indoor features and amenities, including lounging areas and other shared spaces. Optional field.",
            },
            outdoorSpaces: {
              title: "Outdoor Spaces",
              description:
                "Highlight the outdoor elements of your retreat, such as gardens, patios, pools, and scenic views. Optional field.",
            },
            dining: {
              title: "Dining Options",
              description:
                "Provide an overview of the dining settings, (e.g. pool bars, restaurants) available at the accommodations. Optional field.",
            },
            activitiesFitness: {
              title: "Activities & Fitness",
              description:
                "Detail the range of fitness options like on-site exercise facilities. Optional field.",
            },
            spaBeauty: {
              title: "Spa & Beauty",
              description:
                "Outline the spa and beauty services offered, such as massages, facials, and wellness treatments. Optional field.",
            },
            rentals: {
              title: "Rentals",
              description:
                "Inform about any rental services available, including equipment for outdoor activities or special events. Optional field.",
            },
            sustainability: {
              title: "Sustainability practices",
              description:
                "Explain the sustainability practices implemented at your retreat, showcasing your commitment to eco-friendly operations. Mandatory field.",
            },
            map: {
              title: "Map",
              description:
                "Type the address of the accommodation in the box within the map below.",
            },
            location: {
              title: "Location",
              description:
                "Describe what makes the location and neighborhood of your retreat stand out, focusing on the unique aspects and charm of the surrounding area. Highlight notable landmarks, natural scenery, and cultural attractions, and inform potential guests about nearby amenities like restaurants, shops, parks, and historical sites",
            },
            seasonal: {
              title: "Seasonal (optional)",
              description:
                "Illuminate the unique character of each season at your retreat. Describe how the landscape, activities, and overall ambience transform throughout the year. This insight helps guests choose the ideal time to visit, whether they seek a summer adventure under the sun, a tranquil autumn escape, or a cosy winter retreat.",
            },
            directionsAdvisory: {
              title:
                "How To Get There (Flights, Airport Transfers, Buses, Trains)",
              description:
                "Provide detailed guidance on reaching your retreat, including flight options, airport transfers, and available bus or train routes, to assist guests in planning their travel efficiently.",
            },
            weatherAdvisory: {
              title: "Weather Information (optional)",
              description:
                "Provide detailed information about the typical weather conditions guests can expect during different times of the year. Paint a picture of each season's weather patterns, including average temperatures and expected rainfall, helping guests envision and prepare for their ideal retreat experience.",
            },
            whatToBringAdvisory: {
              title: "What to bring (optional)",
              description:
                "List essential items that attendees should bring to your event. This could include specific clothing, gear for activities, personal care items, or any special documentation.",
            },
            vaccinationsAdvisory: {
              title: "Vaccination (optional)",
              description:
                "Specify any vaccination requirements for attendees of your event.",
            },
            insuranceAdvisory: {
              title: "Insurance (optional)",
              description:
                "Detail the insurance requirements for your event. Specify whether attendees need travel insurance, health insurance, or any other specific coverage",
            },
            bookableSpaces: {
              title: "Adding Rooms",
              description:
                "Provide details about the various room types available for booking, including their unique features, sizes, views, and any special amenities they offer to ensure guests can find a space that perfectly suits their needs.",
            },
          },
        },
        events: {
          upsert: {
            intro: {
              title: "Adding Your Retreat Program",
              description:
                "Provide all important information about the program your retreat offers. This thorough description helps guests understand the full scope of what your retreat offers, aiding them in deciding if it aligns with their interests and needs.",
            },
            name: {
              title: "Program Name",
              description:
                "Enter a reference name for your retreat program here. This is for your internal use and organisation. Later, when creating your final listing, you'll have the opportunity to choose a more inspiring and marketable name that captures the essence of your retreat and appeals to potential guests.",
            },
            tagline: {
              title: "Tagline",
              description: "Tagline Description",
            },
            introduction: {
              title: "Introduction",
              description: "Introduction Description",
            },
            highlights: {
              title: "Highlights",
              description: "Highlights Description",
            },
            expectedExperience: {
              title: "What you can expect",
              description:
                "Detail as much as you can from what your users can expect from this experience and what they will walk away having experienced and learned at this event.",
            },
            photos: {
              title: "Photo Upload",
              description:
                "Upload high-quality photos that vividly capture your retreat program, from key activities to the unique environment. Compelling images, especially from past events, are crucial for engaging potential guests and bringing the experience to life. Upload best image first as it will be used as feature image. Please note that our team reserves the right to select the feature image for your listing, based on our understanding of what will most appeal to visitors.",
            },
            eventType: {
              title: "Retreat Category",
              description:
                "Please select the category that best describes your retreat's overall theme or focus.",
            },
            map: {
              title: "Map",
              description: "Map description",
            },
            arrivalInformation: {
              title: "Arrival Information",
              description:
                "Provide detailed instructions for guests regarding their arrival at your retreat. Clarifying these details ensures a smooth and stress-free arrival experience for your guests.",
            },
            itineraryScheduleIntro: {
              title: "Adding Itinerary & Schedule",
              description:
                "Outline the daily itinerary and schedule for your retreat. A clear, well-structured itinerary helps guests understand the flow of their retreat experience and plan their days accordingly.",
            },
            eventDuration: {
              title: "Event Duration (Days)",
              description:
                "Specify the total number of days for your event, including both arrival and departure days.",
            },
            daysWithInstruction: {
              title: "Days with instruction",
              description:
                "Of the {{daysWithInstruction}} days in total, how many days have instruction?",
            },
            minimumGroupSize: {
              title: "Minimum Group Size",
              description:
                "Indicate the minimum number of participants required for your event to take place.",
            },
            maximumGroupSize: {
              title: "Maximum Group Size",
              description:
                "Specify the maximum number of participants you can accommodate for your event.",
            },
            itinerary: {
              title: "Itinerary",
              description:
                "Write 'Overall Description' when your days follow a similar pattern OR you want to provide a general summary of the event's schedule and activities. If each day of your event differs significantly, choose to detail a 'Day-by-Day' itinerary, giving attendees a clear, structured outline of activities and timings for each day.",
              overallInputTitle: "Itinerary Overview",
              dayByDayInputTitle: "Itinerary day-by-day",
            },
            whatsIncluded: {
              title: "What's included",
              description:
                "Specify the key offerings of your retreat package, including the retreat's duration, accommodation details, meal plans, airport transfers, and any special activities or equipment provided, like a trekking trip or aerial equipment. This concise overview helps guests understand the essentials of what's included in their retreat experience.",
            },
            whatsNotIncluded: {
              title: "What's not included",
              description:
                "Clearly list the elements that are not included in your retreat package. e.g. flights, certain meals, specific activities, travel insurance, or personal expenses.",
            },
            optionalExtras: {
              title: "Optional Extras",
              description:
                "List additional activities or services available at your retreat or in the local area that come with an extra charge e.g. special workshops, excursions, spa treatments, or unique local experiences. Highlighting these options allows your guests to explore and take advantage of these enriching activities, enhancing their overall retreat experience.",
            },
            spaTreatments: {
              title: "Spa Treatments",
              description: "Please list spa treatments",
            },
            knowBeforeYouGo: {
              title: "Know before you go",
              description:
                "List essential information and tips that guests should be aware of before arriving at your retreat. This may include travel tips, cultural norms, necessary personal items to bring, any pre-arrival preparations, or health and safety advisories.",
            },
            instructionLanguages: {
              title: "Instruction Languages",
              description:
                "Please select from the list of available languages in which instructions and activities will be conducted during your event.",
            },
            skillLevel: {
              title: "Skill Level",
              description:
                "Choose the appropriate skill level for your event. Options may range from beginner to intermediate to advanced.",
            },
            minimumMaximumAges: {
              title: "Minimum and Maximum Ages",
              description:
                "Please specify the minimum and maximum age limits for participants in this event.",
              minimumInputLabel: "Minimum Age",
              maximumInputLabel: "Maximum Age",
            },
            overallFoodDescription: {
              title: "Food",
              description:
                "Detail the culinary offerings available at your event. Describe the types of meals served, the style of cuisine, and any special themes or highlights of the dining experience. This information helps guests anticipate the gastronomic journey they'll enjoy during their stay.",
            },
            foodSpecifics: {
              title: "Culinary Specifics",
              description:
                "Elaborate on the specifics of your food and drink offerings. Detail the style of cuisine, the uniqueness of your menu, any special dietary options like vegan or gluten-free meals, and the types of drinks available. Use this space to highlight the culinary experience guests can expect, from special dishes to unique beverage selections.",
            },
            includedMeals: {
              title: "Included meals",
              description:
                "Please select all meals that are included with this event.",
            },
            includedFoodDietaryTypes: {
              title: "Included food types / dietary requirements",
              description:
                "Specify the types of meals and any specific dietary requirements catered for in your event. This information is crucial for guests with specific dietary needs or preferences, ensuring they can enjoy their meals worry-free and focus on the overall experience of the retreat.",
            },
            includedDrinks: {
              title: "Included drinks",
              description:
                "Please select what drinks are included with this event.",
            },
            staff: {
              title: "Organisers and Teachers Selection",
              description:
                "Please select which organisers and teachers are part of this listing.",
            },
          },
        },
      },
    },
  },
};

i18n
  // .use(Backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
