import {
  Alert,
  Box,
  Button,
  Checkbox,
  Grid,
  LinearProgress,
  Link,
  Modal,
  ModalClose,
  ModalDialog,
  Typography,
} from "@mui/joy";
import { getAuth } from "firebase/auth";
import { Document } from "iconsax-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  useLazyCmsLegalDocumentByIdQuery,
  useLazyGetAccountQuery,
  useUpdateAccountMutation,
} from "services/osare";

export default function OnboardAgreements() {
  const navigate = useNavigate();
  const auth = getAuth();
  const [isAgreementModalOpen, setIsAgreementModalOpen] = useState(false);
  const [triggerCmsFetch, cmsFetchResult] = useLazyCmsLegalDocumentByIdQuery();
  const [legalCopy, setLegalCopy] = useState(null);
  const [commercialTerms, setCommercialTerms] = useState(null);
  const [accountTrigger, accountTriggerResult] = useLazyGetAccountQuery();
  const [agencyAgreementCheckbox, setAgencyAgreementCheckbox] = useState(false);
  const [agencyAgreementTermsCheckbox, setAgencyAgreementTermsCheckhox] =
    useState(false);
  const [privacyPolicyCheckbox, setPrivacyPolicyCheckbox] = useState(false);
  const [updateAccount, updateAccountResult] = useUpdateAccountMutation();

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        accountTrigger();
      } else {
        navigate("/login");
      }
    });
  }, []);

  useEffect(() => {
    if (cmsFetchResult.isSuccess) {
      // If CMS was successfully fetched,
      // parse the tags and replace with account data
      console.log("CMS Agreement Data", cmsFetchResult.data);
      setLegalCopy(cmsFetchResult.data.results.document);
      setCommercialTerms(cmsFetchResult.data.results.commercialTermsDocument);
    }
  }, [cmsFetchResult]);

  useEffect(() => {
    if (accountTriggerResult.data) {
      console.log("accountTriggerResult", accountTriggerResult.data);
      if (accountTriggerResult.data.core.agreedToAllLegal) {
        navigate("/dash");
      }
    }
  }, [accountTriggerResult.data]);

  // eslint-disable-next-line no-unused-vars
  const openAgreementModal = (agreementCmsId, appendCommercialTerms) => {
    setLegalCopy(null);
    setIsAgreementModalOpen(true);
    triggerCmsFetch({ id: agreementCmsId, appendCommercialTerms });
  };

  const agreeToAllLegal = () => {
    updateAccount({
      agreedToAllLegal: Date.now(),
    });
  };

  useEffect(() => {
    console.log(updateAccountResult.data);
    if (updateAccountResult.isSuccess) {
      navigate("/dash");
    }
  }, [updateAccountResult]);

  const AgreementModalComponent = () => {
    return (
      <Modal open={isAgreementModalOpen}>
        <ModalDialog
          color="primary"
          layout="center"
          size="md"
          variant="outlined"
        >
          <ModalClose
            onClick={() => {
              setIsAgreementModalOpen(false);
              setLegalCopy(null);
            }}
          />

          {cmsFetchResult.isFetching ? (
            <>
              <Typography gutterBottom level="title-lg">
                Just a moment...
              </Typography>
              <LinearProgress />
            </>
          ) : (
            <>
              <Typography level="title-lg">{legalCopy?.title}</Typography>
              <div
                style={{ overflowY: "scroll" }}
                dangerouslySetInnerHTML={{
                  __html: `${legalCopy?.body} ${
                    commercialTerms === null ? "" : commercialTerms?.body
                  }`,
                }}
              />
            </>
          )}
        </ModalDialog>
      </Modal>
    );
  };

  const LoadingComponent = () => {
    return <Typography>Loading...</Typography>;
  };

  return (
    <>
      <AgreementModalComponent />

      {accountTriggerResult.isFetching ? (
        <LoadingComponent />
      ) : (
        <>
          <Box mt={5} textAlign={"center"}>
            <Typography level="h2">Legal Agreements</Typography>
            <Typography level="body-lg">
              Please read and confirm that you agree to the following
              agreements.
            </Typography>
          </Box>

          <Grid container spacing={3} m={3}>
            <Grid xs={0} md={3}></Grid>
            <Grid xs={12} md={6}>
              <Alert
                startDecorator={<Document />}
                endDecorator={
                  <Checkbox
                    checked={agencyAgreementCheckbox}
                    label="I agree"
                    onChange={(e) =>
                      setAgencyAgreementCheckbox(e.target.checked)
                    }
                  />
                }
                variant="outlined"
                color="primary"
              >
                <Typography>
                  <Link
                    onClick={() => openAgreementModal("agency_agreement", true)}
                  >
                    Agency Agreement
                  </Link>
                </Typography>
              </Alert>
            </Grid>
            <Grid xs={0} md={3}></Grid>

            <Grid xs={0} md={3}></Grid>
            <Grid xs={12} md={6}>
              <Alert
                startDecorator={<Document />}
                endDecorator={
                  <Checkbox
                    checked={agencyAgreementTermsCheckbox}
                    onChange={(e) =>
                      setAgencyAgreementTermsCheckhox(e.target.checked)
                    }
                    label="I agree"
                  />
                }
                variant="outlined"
                color="primary"
              >
                <Typography>
                  <Link
                    onClick={() =>
                      openAgreementModal("agency_terms_conditions")
                    }
                  >
                    Agency Terms &amp; Conditions
                  </Link>
                </Typography>
              </Alert>
            </Grid>
            <Grid xs={0} md={3}></Grid>

            <Grid xs={0} md={3}></Grid>
            <Grid xs={12} md={6}>
              <Alert
                startDecorator={<Document />}
                endDecorator={
                  <Checkbox
                    checked={privacyPolicyCheckbox}
                    onChange={(e) => setPrivacyPolicyCheckbox(e.target.checked)}
                    label="I agree"
                  />
                }
                variant="outlined"
                color="primary"
              >
                <Typography>
                  <Link onClick={() => openAgreementModal("privacy_policy")}>
                    Privacy Policy
                  </Link>
                </Typography>
              </Alert>
            </Grid>
            <Grid xs={0} md={3}></Grid>

            <Grid xs={0} md={3}></Grid>
            <Grid mt={3} xs={12} md={6}>
              <Button
                disabled={
                  !privacyPolicyCheckbox ||
                  !agencyAgreementCheckbox ||
                  !agencyAgreementTermsCheckbox ||
                  updateAccountResult.isLoading
                }
                color="success"
                variant="soft"
                fullWidth
                onClick={agreeToAllLegal}
              >
                {updateAccountResult.isLoading
                  ? "Saving..."
                  : "I have read and agreed to all agreements"}
              </Button>
            </Grid>
            <Grid xs={0} md={3}></Grid>
          </Grid>
        </>
      )}
    </>
  );
}
