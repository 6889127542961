import firebase from "firebase/compat/app";
import "firebase/compat/auth";

// Configure Firebase.
// eslint-disable-next-line no-undef
console.log(process.env.REACT_APP_FIREBASE_CONFIG);
// eslint-disable-next-line no-undef
const config = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG);
// const config = {
//   "apiKey": "AIzaSyDXPTe8IPcpNuPHbOGkc1WVMFgiaz7mvt4",
//   "authDomain": "osare-platform-staging.firebaseapp.com",
//   "projectId": "osare-platform-staging",
//   "storageBucket": "osare-platform-staging.appspot.com",
//   "messagingSenderId": "481573779950",
//   "appId": "1:481573779950:web:66731504904637f36136ff",
//   "measurementId": "G-X92HVLKHMS",
// };

firebase.initializeApp(config);
// connectAuthEmulator(auth, "http://localhost:9099");

export const firebaseApp = firebase;
