import {
  Button,
  DialogContent,
  DialogTitle,
  Modal,
  ModalDialog,
  Stack,
} from "@mui/joy";
import { useEffect } from "react";
import { useCancelOrderMutation } from "services/osare";

export default function CancelOrderModal(props) {
  const [cancelOrder, cancelOrderStatus] = useCancelOrderMutation();

  useEffect(() => {
    if (cancelOrderStatus.isSuccess) {
      props.closeFn(false);
      props.refetchOrderDataFn();
    }

    if (cancelOrderStatus.isError) {
      console.error(cancelOrderStatus.error);
      alert(
        `Sorry, an error occured whilst trying to cancel the booking:\n\n${cancelOrderStatus.error.data.errors.join(
          ", "
        )}\n\nPlease try again, or contact our support team if the problem persists.`
      );
    }
  }, [cancelOrderStatus]);

  console.log("CancelOrderModal", props);
  return (
    <Modal open={props.open} onClose={() => props.closeFn(false)}>
      <ModalDialog color="danger" variant="soft">
        <DialogTitle>Cancel this order</DialogTitle>
        <DialogContent>
          This order will be cancelled and you will need to ensure that the
          customer is refunded in accordance with your stated refund range. The
          inventory for this booking will be returned to the listing so it can
          be rebooked.
        </DialogContent>
        <form
          onSubmit={(event) => {
            event.preventDefault();
          }}
        >
          <Stack spacing={2}>
            <Button
              disabled={cancelOrderStatus.isLoading}
              color="danger"
              type="submit"
              onClick={() => cancelOrder({ orderId: props._id })}
            >
              {cancelOrderStatus.isLoading ? "Cancelling..." : "Cancel Booking"}
            </Button>
          </Stack>
        </form>
      </ModalDialog>
    </Modal>
  );
}
