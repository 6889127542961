import * as Sentry from "@sentry/react";
import React from "react";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./i18n";
import reportWebVitals from "./reportWebVitals";

Sentry.init({
  enabled: window.location.hostname !== "localhost",
  // eslint-disable-next-line no-undef
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^127.0.0.1/],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const container = document.getElementById("root");

const root = createRoot(container);
const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/logout",
    element: <Logout />,
  },
  {
    path: "/onboard",
    element: <OnboardWelcome />,
  },
  {
    path: "/agreements",
    element: <OnboardAgreements />,
  },
  {
    path: "/dash",
    element: <DashContainer />,
    children: [
      {
        path: "wizard",
        element: <WizardCreate />,
      },
      {
        path: "wizard/:wizardId",
        element: <Wizard />,
        children: [
          {
            path: "finished",
            element: <WizardFinished />,
          },
          {
            path: "listing",
            children: [
              {
                path: "basics",
                element: <WizardListingsTheBasics />,
              },
              {
                path: "itinerary",
                element: <WizardListingsItinerary />,
              },
              {
                path: "food-and-drink",
                element: <WizardListingsFoodDrink />,
              },
              {
                path: "documents",
                element: <WizardListingDocuments />,
              },
            ],
          },
          {
            path: "accommodation",
            children: [
              {
                path: "overview",
                element: <WizardAccommodationOverview />,
              },
              {
                path: "rooms",
                element: <WizardAccommodationRooms />,
              },
              {
                path: "location",
                element: <WizardAccommodationLocation />,
              },
            ],
          },
          {
            path: "pricing",
            children: [
              {
                path: "booking-settings",
                element: <WizardPricingBooking />,
              },
              {
                path: "dates-prices",
                element: <WizardPricingDatesPricing />,
              },
              {
                path: "estimated-earnings",
                element: <WizardEstimatedEarnings />,
              },
              {
                path: "policies",
                element: <WizardPricingPolicies />,
              },
              {
                path: "options",
                element: <WizardPaymentOptions />,
              },
            ],
          },
        ],
      },
      {
        path: "orders",
        children: [
          {
            path: "single",
            element: <DashOrderSingle />,
          },
        ],
      },
      {
        path: "listings",
        element: <DashListingsList />,
        children: [
          {
            path: "new",
            element: <DashListingsUpsert />,
          },
          {
            path: "edit",
            element: <DashListingsUpsert />,
          },
        ],
      },
      {
        path: "accommodation",
        element: <DashAccommodationList />,
        children: [
          {
            path: "new",
            element: <DashAccommodationNew />,
          },
          {
            path: "edit",
            element: <DashAccommodationNew />,
          },
        ],
      },
      {
        path: "events",
        element: <DashEventsList />,
        children: [
          {
            path: "new",
            element: <DashEventsUpsert />,
          },
          {
            path: "edit",
            element: <DashEventsUpsert />,
          },
        ],
      },
      {
        path: "settings",
        element: <DashSettings />,
        children: [
          {
            path: "payouts",
            element: <Payouts />,
          },
          {
            path: "support-contact",
            element: <SettingsSupportContact />,
          },
        ],
      },
      {
        path: "staff",
        element: <DashStaff />,
        children: [
          {
            path: "edit",
            element: <DashStaffEdit />,
          },
        ],
      },
    ],
  },
]);

// Redux
import DashContainer from "pages/Dash/DashContainer";
import DashAccommodationList from "pages/Dash/Screens/Accommodation/Accommodation";
import DashAccommodationNew from "pages/Dash/Screens/Accommodation/Upsert/Upsert";
import DashEventsList from "pages/Dash/Screens/Events/Events";
import DashEventsUpsert from "pages/Dash/Screens/Events/Upsert/Upsert";
import DashListingsList from "pages/Dash/Screens/Listings/Listings";
import DashListingsUpsert from "pages/Dash/Screens/Listings/Upsert/Upsert";
import DashOrderSingle from "pages/Dash/Screens/Orders/SingleOrder";
import Payouts from "pages/Dash/Screens/Settings/Payouts/Payouts";
import DashSettings from "pages/Dash/Screens/Settings/Settings";
import SettingsSupportContact from "pages/Dash/Screens/Settings/SupportContact/SupportContact";
import DashStaffEdit from "pages/Dash/Screens/Staff/Edit/Edit";
import DashStaff from "pages/Dash/Screens/Staff/Staff";
import WizardAccommodationLocation from "pages/Dash/Screens/Wizard/Accommodation/Location";
import WizardAccommodationOverview from "pages/Dash/Screens/Wizard/Accommodation/Overview";
import WizardAccommodationRooms from "pages/Dash/Screens/Wizard/Accommodation/Rooms";
import WizardFinished from "pages/Dash/Screens/Wizard/Finished/Finished";
import WizardListingDocuments from "pages/Dash/Screens/Wizard/Listings/Documents";
import WizardListingsFoodDrink from "pages/Dash/Screens/Wizard/Listings/FoodDrink";
import WizardListingsItinerary from "pages/Dash/Screens/Wizard/Listings/Itinerary";
import WizardListingsTheBasics from "pages/Dash/Screens/Wizard/Listings/TheBasics";
import WizardPricingBooking from "pages/Dash/Screens/Wizard/Pricing/Booking";
import WizardPricingDatesPricing from "pages/Dash/Screens/Wizard/Pricing/DatesPricing";
import WizardEstimatedEarnings from "pages/Dash/Screens/Wizard/Pricing/EstimatedEarnings";
import WizardPaymentOptions from "pages/Dash/Screens/Wizard/Pricing/PaymentOptions";
import WizardPricingPolicies from "pages/Dash/Screens/Wizard/Pricing/Policies";
import Wizard from "pages/Dash/Screens/Wizard/Wizard";
import WizardCreate from "pages/Dash/Screens/Wizard/WizardCreate";
import Home from "pages/Home";
import Login from "pages/Login";
import Logout from "pages/Logout";
import OnboardAgreements from "pages/Onboard/Agreements";
import OnboardWelcome from "pages/Onboard/Welcome";
import { Provider } from "react-redux";
import { store } from "./store";

root.render(
  <Provider store={store}>
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
